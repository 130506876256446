const HelpBoxTextVolunteering = {

	/*################################################################################################################################################
	Cadastro de Projetos #############################################################################################################################
	################################################################################################################################################*/
	ProjectList: 
	`
	<h3>Projetos</h3>
	<p>Clique em “+ Novo projeto” para iniciar o cadastro de um novo projeto.</p>
	<h4>Projetos em captação</h4>
	<p>Nesta fase os projetos estão em captação de recursos e ainda não estão sendo executados.</p>
	<h4>Projetos em liberação</h4>
	<p>Quando o projeto entra em fase de liberação total ou parcial de recursos para entrar em execução.</p>
	<h4>Projetos em execução</h4>
	<p>O projeto entra em fase de execução, o que não impede que o mesmo continue com captação de recursos em paralelo caso necessário.</p>
	<h4>Aguardando encerramento</h4>
	<p>Quando a instituição solicita o encerramento do projeto e este aguarda liberação por parte da AUC. Ao entrar nessa fase o projeto encontra-se temporariamente fechado.</p>
	<h4>Encerrados</h4>
	<p>Fase em que o projeto foi encerrado pela AUC à partir da solicitação da instituição. O projeto estará permanentemente fechado.</p>
	`,
	ProjectType: 
	`<p>
		<h4>Projeto sem incentivo fiscal:</h4> 
		São os projetos desenvolvidos pela instituição que recebem doações por outras fontes que mas não pelas leis de incentivo.
		Atualmente é possível cadastrar na plataforma projetos autorizados a captar pelo:
		<h4>Projeto com incentivo fiscal:</h4>
		São os projetos que foram aprovados para captação de recursos pelo imposto de renda pelos órgãos governamentais competentes.
		<br>
		<strong>Nível Federal:</strong>
		<br>
		<ul>
			<li>Fundos do Idoso – Estatuto do Idoso<br></li>
			<li>Lei Federal de Fomento ao Desporto - LEI<br></li>
			<li>Fundo da Criança e Adolescente – Estatuto da Criança e do Adolescente</li>
		</ul>
	</p>
	`,	
	ProjectStep1: {
		Description: {
			FullName: 
			`
			<h3>Passo 1</h3>
			<h4>Vamos começar?</h4>
			<p>Informe aqui as informações sobre a descrição e características do projeto.</p>
			<p>Nome completo do projeto: Insira o nome do projeto.</p>
			`,
			ButtonInput: `<button style="width: 193px;border: 1px solid #FFFFFF;border-radius: 23px;height: 46px;margin-top: 15px;"><i class="fal fa-long-arrow-alt-left" style="margin-right:10px"></i>Ir para o campo</button>`,
			ShortName:
			`
			<h3>Passo 1</h3>
			<h4>Nome Resumido</h4>
			<p>Resuma o nome do seu projeto ou insira uma sigla!</p>
			`,
			Couse:
			`
			<h3>Passo 1</h3>
			<h4>Causa</h4>
			<p>Um projeto pode ter muitas causas! Escolha a principal!</p>
			`,
			IncentiveLaw:
			`
			<h3>Passo 1</h3>
			<h4>Lei de Incentivo</h4>
			<p>Selecione qual a lei que o projeto está inserido!</p>
			`,
			ProcessNumber: 
			`
			<h3>Passo 1</h3>
			<h4>Nº do processo</h4>
			<p>Todo projeto cadastrado em leis de incentivo recebe um número de processo. Preencha com essa informação.</p>
			`,
			PublicationDateDOU:
			`
			<h3>Passo 1</h3>
			<h4>Data de publicação DOU</h4>
			<p>Data de publicação da portaria de aprovação do DOU.</p>
			`,
			Manifestation:
			`
			<h3>Passo 1</h3>
			<h4>Manifestação desportiva</h4>
			<p>Projetos aprovados pela lei do esporte pode ser classificados em 3 categorias. Selecione em qual o projeto se encaixa.</p>
			`,
			PublicTarget:
			`
			<h3>Passo 1</h3>
			<h4>Público</h4>
			<p>Selecione o público principal do projeto.</p>
			`,
			Children:
			`
			<h3>Passo 1</h3>
			<h4></h4>
			<p></p>
			`,
			Old: 
			`
			<h3>Passo 1</h3>
			<h4></h4>
			<p></p>
			`,
			Sporting:
			`
			<h3>Passo 1</h3>
			<h4></h4>
			<p></p>
			`,
			SportLaw:
			`
			<h3>Passo 1</h3>
			<h4></h4>
			<p></p>
			`,
			PublicTarget:
			`
			<h3>Passo 1</h3>
			<h4></h4>
			<p></p>
			`,
		},
		Ods: `Os Objetivos de Desenvolvimento Sustentável são uma coleção de 17 metas globais estabelecidas pela Assembleia Geral das Nações Unidas. Selecione aquelas que o projeto abrange.`,
		Objective: `Descreva aquilo que o projeto pretende alcançar, o propósito de existência do projeto. Ex: Aumentar as taxas do nível educacional da região.`,
		SocialProblem: `Conte-nos sobre a situação ou condição que o projeto quer resolver ou ajudar. A inclusão de dados quantitativos é importante para compreensão da abrangência do problema. Ex: 20 Crianças sem atividades de contraturno escolar.`,
		Solution: `Explique de forma resumida as possíveis soluções para o problema social descrito acima. Ex: Investir em atividades extracurriculares, expandir a grande horária das escolas.`,
		Methodology: `Explique detalhadamente qual método será aplicado no projeto para alcançar os objetivos e avaliar o impacto.`,
		PublicTarget: `Agora descreva qualitativamente o público alvo selecionado anteriormente.  Ex: Crianças e Adolescentes estudantes de escolas municipais localizadas no bairro do Capão Redondo.`
	},

	ProjectStep2: 
	`
	<h3>Passo 2</h3>
	<h4>Abrangência</h4>
	<p>Adicone as áreas de atuação do projeto.</p>
	<p>Produtos de doação: Define os valores mínimos para doações e conte o que poderá ser realizado. É importante incluir quantidade/tempo nesta descrição! Ex: Doe 50 reais e patrocine a alimentação de 1 criança por semana.</p>
	`,
	
	ProjectStep3: 
	`
	<h3>Passo 3</h3>
	<p><strong>Nesta etapa você poderá detalhar mais sobre o perfil dos beneficiários diretos do projeto. Caso eles não se enquadrem nas opções ao lado você pode clicar em “Informar somente beneficiários diretos” e preencher com a descrição que melhor se adeque.</strong></p>
	<h4>Beneficiários Diretos</h4>
	<p>É o número de pessoas que serão atendidas/participarão do projeto.</p>
	<h4>Beneficiários Indiretos</h4>
	<p>É o número de pessoas impactadas indiretamente pelo projeto. Ex: Membros da família, vizinhos, colegas de turma.</p>
	<p>
		<strong>Os beneficiários serão os mesmos ou irão variar ao longo do projeto? BOX Mesmos beneficiários/ Beneficiários diferentes.</strong>
	</p>
	<p>
		<strong>Mesmos beneficiários</strong> – Esta opção é para o projeto que atende as mesmas pessoas do começo ao fim. Ex: As mesmas crianças de 09 até 12 anos são atendidas durante todo o ano.
	</p>
	<p>
		<strong>Beneficiários diferentes</strong> – Esta opção é para os projetos que terão participantes diferentes durante sua execução.  não atendem as mesmas pessoas ao longo do projeto. Ex: Primeiro semestre atende crianças de 09 a 12 anos. No segundo semestre atende crianças de 12 a 15 anos.
	</p>
	`,
	
	ProjectStep4: 
	`
	<h3>Passo 4</h3>
	<h4>Cronograma de Projeto</h4>
	<p>Preencha com a data prevista de início e término do projeto. Você poderá estender ou reduzir esse prazo depois!</p>
	<h4>Cronograma de Atividades</h4>
	<p>Informe as atividades que serão realizadas para atingir o objetivo do projeto.</p>
	`,
	
	ProjectStep5: 
	`
	<h3>Passo 5</h3>
	<h4>Detalhamento das Metas</h4>
	<p>
		As metas é tudo aquilo que precisa ser realizado para o projeto alcançar seus objetivos com sucesso. Determine quais as metas mais relevantes para guiar o projeto. Ex: Alfabetizar 200 crianças.
	</p>
	<h4>Investimento</h4>
	<p>Preencha com as informações financeiras sobre o projeto</p>
	<p>
		<strong>Valor total do projeto:</strong>
		Informe o valor autorizado ou aprovado, pelo órgão competente, para captação do projeto.
	</p>
	<p>
		<strong>Valor já captador com outros parceiros:</strong>
		Caso seu projeto já tenha captado doações, informe qual valor. Esta informação vai ficar disponível no perfil do projeto
	</p>
	<p>
		<strong>Data limite para captação</strong>
		Caso o projeto tenha um prazo máximo para captar recursos preencha ao lado. Caso não tenha prazo, coloque até o fim do ano subsequente. Você será lembrado de atualizar!
	</p>
	<h4>Contrapartidas</h4>
	<p>
		<strong>Nome da cota</strong>
		Caso os valores de doação tenham sido categorizados, acrescente a nomenclatura utilizada. Ex: Cota Diamante, Cota Ouro.
	</p>
	<p>
		<strong>Benefícios</strong>
		Descreva os benefícios da cota.
	</p>
	`,
	
	ProjectStep50:
	`<h3>Frequência de comprovação de metas</h3>
	<h4>Escolha o período</h4>
	<p>A comprovação das metas acontecerá através da plataforma na frequência escolhida ao lado</p>
	<p>Atenção! Estas atualizações serão compartilhadas com os doadores do projeto.</p>
	`,
	Documents:
	`
	<h3>Documentos</h3>
	<h4>Documentos do Projeto Aprovado</h4>
	<p>Qualem igitur hominem natura inchoavit? De ingenio eius in his disputationibus, non de moribus quaeritur.Qua tu etiam inprudens utebare non numquam. Itaque contra est, ac dicitis</p>
	<h4>Documentos de Aprovação e Certificação</h4>
	<p>Qualem igitur hominem natura inchoavit? De ingenio eius in his disputationibus, non de moribus quaeritur.Qua tu etiam inprudens utebare non numquam. Itaque contra est, ac dicitis; Aliter enim explicari, quod quaeritur, non potest. </p>
	<h4>Comprovante de Conta Bancária</h4>
	<p>Qualem igitur hominem natura inchoavit? De ingenio eius in his disputationibus, non de moribus quaeritur.Qua tu etiam inprudens utebare non numquam. Itaque contra est, ac dicitis; Alit</p>
	<h4>Apresentação</h4>
	<p>Qualem igitur hominem natura inchoavit? De ingenio eius in his disputationibus, non de moribus quaeritur.Qua tu etiam inprudens utebare non numquam. Itaque contra est, ac dicitis; Aliter enim explicari, quod quaeritur, non potest. </p>
	`,

	/*#################################################################################################################################################
	Perfil ############################################################################################################################################
	#################################################################################################################################################*/
	Profile: 
	`<h3>Perfil</h3>
	<h4>Preencha cuidadosamente</h4>
	<p>Qualem igitur hominem natura inchoavit? De ingenio eius in his disputationibus, non de moribus quaeritur.Qua tu etiam inprudens utebare non numquam. Itaque contra est, ac dicitis; Aliter enim explicari, quod quaeritur, non potest. Quicquid porro animo cernimus, id omne oritur a sensibus; Omnes enim iucundum motum, quo sensus hilaretur. Conferam tecum, quam cuique verso rem subicias; Minime vero, inquit ille, consentit. Sic vester sapiens magno aliquo emolumento commotus cicuta, si opus erit, dimicabit.</p>
	`,
	
	/*#################################################################################################################################################
	Cadastro de Campanhas #############################################################################################################################
	#################################################################################################################################################*/
	CampaignEdit: 
	`
	<h3>Edição de Campanhas</h3>
	<p>Clique em “+ Nova campanha” para iniciar o cadastro de uma nova campanha.</p>
	<h4>Campanhas novas</h4>
	<p>Nesta fase as campanhass estão em fase de cadastro e ainda não estão sendo executadas.</p>
	<h4>Em andamento</h4>
	<p>Quando a campanha entra em andamento, a campanha está publicada e já apta a visitação pública.</p>
	<h4>Concluídas</h4>
	<p>Campanhas concluídas já chegaram a sua data limite ou já atingiram o limite de recursos a serem captados.</p>
	`,
	CampaignList: 
	`
	<h3>Campanhas</h3>
	<p>Clique em “+ Nova campanha” para iniciar o cadastro de uma nova campanha.</p>
	<h4>Campanhas novas</h4>
	<p>Nesta fase as campanhass estão em fase de cadastro e ainda não estão sendo executadas.</p>
	<h4>Em andamento</h4>
	<p>Quando a campanha entra em andamento, a campanha está publicada e já apta a visitação pública.</p>
	<h4>Concluídas</h4>
	<p>Campanhas concluídas já chegaram a sua data limite ou já atingiram o limite de recursos a serem captados.</p>
	`,
	CampaignFaq: 
	`
	<h3>FAQ</h3>
	<p>Clique no botão “+ Nova Pergunta” ao lado para inserir novas questões no faq.</p>
	<p>Cadastre todas as perguntas mais frequentes feitas pelos usuários para a sua campanha.</p>
	`,
	/*#################################################################################################################################################
	Empresas ##########################################################################################################################################
	#################################################################################################################################################*/
	CompanyProfile: 
	`<h3>Perfil da empresa</h3>
	<p>Conte-nos sobre a Empresa! Preencha as lacunas com informações incluindo logo, grupos, filiais, localização e nº de funcionários.</p>
	`,
	/*#################################################################################################################################################
	Empresas ##########################################################################################################################################
	#################################################################################################################################################*/
	CouncilProfile: 
	`<h3>Perfil do Conselho</h3>
	<p>Nesta área devem ser preenchidas todos os dados cadastrais do conselho e respectivo fundo. Todos os dados são obrigatórios, inclusive o logotipo.</p>
	`,
	/*#################################################################################################################################################
	Instituições ######################################################################################################################################
	#################################################################################################################################################*/
	About:
	`
	<h3>Sobre a instituição</h3>
	<h4>Missão</h4>
	<p>A missão é a razão pela qual a Instituição existe, o motivo pelo qual ela foi concebida e projetada!</p>
	<h4>Galeria de Fotos</h4>
	<p>Faça upload de imagens da instituição e os projetos formato .jpg ou .png. Estas fotos serão exibidas no perfil da instituição, portanto, priorize imagens com qualidade e boa resolução.</p>
	<h4>Depoimentos</h4>
	<p>Conte como a Instituição transforma vidas! Cadastre depoimentos de beneficiários dos projetos e todos aqueles que são apaixonados pela causa.</p>
	<h4>Parceiros</h4>
	<p>Reconheça todos que fazem parte da sua história! Cadastre nomes e logos dos parceiros da instituição.</p>
	<p>
		<strong>Atenção!</strong> 
		Cadastre sempre logos com qualidade, fundo branco ou transparente. Observe se não há muita margem branca ou vazia em volta do logotipo, isso prejudicará a visualização, deixando-o menor. JPG ou PNG. Não deixe de clicar no botão Exibir para garantir que eles apareçam no seu perfil.
	</p>
	`,
	InstitutionProfile:
	`<h3>Perfil da Instituição</h3>
	<p>Conte-nos sobre a Instituição! Preencha as lacunas com informações incluindo documentos, certidões e informações cadastrais.</p>
	`,
	Empty:
	`
	<h3></h3>
	<h4></h4>
	<p></p>
	`,
	/*#################################################################################################################################################
	Usuários ##########################################################################################################################################
	#################################################################################################################################################*/
	Users:
	`
	<h3>Usuários</h3>
	<p>
		Agora é hora de convidar os colegas de trabalho da equipe administrativa para terem acesso ao perfil da Instituição que você criou! São as pessoas que precisam ter acesso às informações estratégicas da Instituição. Adicione algumas informações cadastrais para eles receberem o link para cadastro. Você deverá escolher o perfil de acesso deles.
	</p>
	<p>
		<strong>Administrador: </strong>Este perfil será o administrador da conta, poderá fazer qualquer tipo de alteração nos dados cadstrais, acessar área de recibos e fluxo de recursos.
	</p>
	<p>
		<strong>Gestor: </strong> Este perfil terá acesso limitado e não poderá editar projetos e informações cadastrais. Mas poderá cadastrar notícias, imagens, atividades, arquivos e acompanhar o desenvolvimento de campanhas e doações.
	</p>
	`,
	Taxes:
	`
	<h3>Edição de Taxas</h3>
	<p>Aqui são configuradas as taxas para transação de doação.</p>
	<h4>Boleto</h4>
	<p>A taxa deve ser inserida em valores reais, ex.: R$ 0,50</p>
	<h4>Cartão de Crédito</h4>
	<p>A taxa deve ser inserida em porcentagem, ex.: 5,00</p>
	<h4>Cartão de Crédito Recorrente</h4>
	<p>A taxa deve ser inserida em porcentagem, ex.: 4,50</p>
	`,
	TicketConfig:
	`
	<h3>Edição de Convênios</h3>
	<p>Aqui você pode gerenciar os convênvios de boleto</p>
	<h4>Quais os campos necessários?</h4>
	<p>Você precisará saber o código do e-commerce e o código de referência de transação</p>
	<h4>Código do e-commerce</h4>
	<p>Composto por 6 digitos númericos, ex.: 654321</p>
	<h4>Código de referência de transação</h4>
	<p>Também conhecido como REFTRAN, é composto por 17 digitos numéricos, ex.: 76453210000000001</p>
	`,
	ProjectAccountability:
	`
	<h3>Comprovação de Metas</h3>
	<p>Informe os alvos alcançados em comparação com as metas estipuladas para o projeto</p>
	`

};

export { HelpBoxTextVolunteering };