import BaseCrudService from "./baseCrud.service";

export default class VolunteeringService extends BaseCrudService {

	constructor() {
		super('Volunteering');
	}

	findById(id) {
		return this.getRequest(`FindById/${id}`);
	}

	findByProjectId(projectId){
		return this.getRequest(`FindByProjectId/${projectId}`);
	}

	listAll(entityId, institutionId) {
		if (institutionId != null)
			return this.getRequest('ListAll', { entityId });
		else
			return this.getRequest('ListAll');
	}

	save(volunteering) {
		return this.postRequest('Save', volunteering);
	}

	delete(id) {
		return this.deleteRequest(`Delete/${id}`).catch(err => console.log(err));
	}

	deleteCoverage(coverageId) {
		return this.deleteRequest('DeleteCoverage', { id: coverageId });
	}

	publish(volunteeringId) {
		return this.getRequest('Publish', { volunteeringId });
	}

	unpublish(volunteeringId) {
		return this.getRequest('Unpublish', { volunteeringId });
	}

	getAllVolunteeringReport(companyId) {
		return this.getRequest('GetVolunteeringReport', { companyId })
	}

	getVolunteeringInformationReport(subdomain) {
		return this.getRequest('GetVolunteeringInformationReport', { subdomain })
	}

	saveTestimony(testimony) {
		return this.postRequest("SaveVolunteeringTestimony", testimony);
	}

	deleteTestimony(testimonyId) {
		return this.deleteRequest("DeleteVolunteeringTestimony", { testimonyId });
	}

	listTestimoniesByVolunteeringId(volunteeringId) {
		return this.getRequest("ListVolunteeringTestimonyByVolunteeringId", { volunteeringId });
	}

	saveVolunteeringFile(volunteeringFile) {
		return this.postRequest("SaveVolunteeringFile", volunteeringFile);
	}

	listVolunteeringFiles(volunteeringId) {
		return this.getRequest("ListVolunteeringFilesByVolunteeringId", { volunteeringId })
	}

	deleteVolunteeringFile(volunteeringFileId) {
		return this.deleteRequest("DeleteVolunteeringFile", { volunteeringFileId });
	}

	findInstitutionByVolunteeringId(volunteeringId) {
		return this.getRequest("FindInstitutionByVolunteeringId", { volunteeringId });
	}

}